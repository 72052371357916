import { useMemo, useState } from "react";
import { Trade } from "./Trade";
import { ISelectOption, Select } from "../shared/Select";
import { Orderbook } from "./Orderbook";
import { ContentWrapper, DebugWrapper, OptionsWrapper, SelectWrapper, Title } from "./style";
import { Sequence } from "./Sequence";

enum DebugEnum {
  TRADE,
  ORDERBOOK,
  SEQUENCE,
}

export function Debug() {
  const [debugOption, setDebugOption] = useState<DebugEnum>(DebugEnum.TRADE);

  const options: ISelectOption[] = useMemo(
    () => [
      {
        label: "Trades by ID",
        isActive: debugOption === DebugEnum.TRADE,
        onClick: () => setDebugOption(DebugEnum.TRADE),
      },
      {
        label: "Orderbook by Trade ID",
        isActive: debugOption === DebugEnum.ORDERBOOK,
        onClick: () => setDebugOption(DebugEnum.ORDERBOOK),
      },
      {
        label: "Orderbook by Seq. No.",
        isActive: debugOption === DebugEnum.SEQUENCE,
        onClick: () => setDebugOption(DebugEnum.SEQUENCE),
      },
    ],
    [debugOption]
  );

  const pageContent = useMemo(() => {
    if (debugOption === DebugEnum.TRADE) {
      return <Trade />;
    }

    if (debugOption === DebugEnum.SEQUENCE) {
      return <Sequence />;
    }

    return <Orderbook />;
  }, [debugOption]);

  return (
    <DebugWrapper>
      <OptionsWrapper>
        <SelectWrapper>
          <Select options={options} />
        </SelectWrapper>
      </OptionsWrapper>
      <ContentWrapper>{pageContent}</ContentWrapper>
    </DebugWrapper>
  );
}
