import currency from "currency.js";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useSortBy, useTable } from "react-table";
import Collapse from "antd/es/collapse/Collapse";
import Flex from "antd/es/flex";
import { COLORS } from "../../../constants/design/colors";
import { IAevoTradeHistory } from "../../../interfaces/aevo";
import { getProfitTextColor, shortenString } from "../../../utils/strings";
import { DefaultCellForColumn } from "../../Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../../Table/DefaultHeaderForColumn";
import { ITableColumn } from "../../Table/TableColumn";
import { Align, MinifiedTableWrapper, PageGrid, TableHeaderCell, TableLoader, FilterWrapper } from "../../Table/style";
import DownloadCSVButton from "../../shared/DownloadCSVButton";
import { Select } from "../../shared/Select";
import { QueryTitle, RedirectButton } from "../style";
import { MarketDropdown } from "../../Dropdown/MarketDropdown";
import { TradeTypeEnum } from "../../../hooks/api/admin/useAccount";
import arrow from "../../../assets/svg/arrow-up-right.svg";
import { TableFilterDropdown, TableFilterEnum } from "../../Dropdown/TableFilterDropdown";
import { useAccountTrades } from "../../../hooks/api/admin/useAccountTrades";
import { secondsToNanos } from "../../../utils/date";
import TradeHistoryChart from "../charts/TradeHistoryChart";
import { SPACING } from "../../../constants/design/spacing";
import CollapseHeaderCount from "../../TableCollapse";

interface IAevoTradeHistoryTableProps {
  tradeTypes: Set<TradeTypeEnum>;
  setTradeTypes: (tradeTypes: Set<TradeTypeEnum>) => void;
  isMobile?: boolean;
}

export function TradeHistoryTable({ tradeTypes, setTradeTypes, isMobile }: IAevoTradeHistoryTableProps) {
  const navigate = useNavigate();
  const [selectedAsset, setAsset] = useState<string | undefined>(undefined);
  const [limit, setLimit] = useState<number | undefined>(500);
  const [startTime, setStartTime] = useState<number>(0);
  const [endTime, setEndTime] = useState<number>(0);

  const onSetRange = useCallback((range: Array<Date | undefined>) => {
    setLimit(undefined);

    if (range[0]) {
      setStartTime(secondsToNanos(moment(range[0]).unix()));

      // If there is no endDate, set to end of day
      if (range[1]) {
        setEndTime(secondsToNanos(moment(range[1]).unix()));
      } else {
        setEndTime(secondsToNanos(moment(range[0]).startOf("day").unix()));
        setEndTime(secondsToNanos(moment(range[0]).endOf("day").unix()));
      }
    }
  }, []);

  const onSetLimit = useCallback((l: number) => {
    setLimit(l);
    setStartTime(0);
    setEndTime(0);
  }, []);

  const { data, isLoading, isValidating } = useAccountTrades(
    undefined,
    selectedAsset,
    limit,
    startTime,
    endTime,
    Array.from(tradeTypes)
  );

  const onNavigateToDebug = useCallback(
    (tradeId: string) => {
      navigate(`/debug?tradeId=${tradeId}`);
    },
    [navigate]
  );

  const onSet = useCallback(
    (type: TradeTypeEnum) => {
      if (tradeTypes.has(type)) {
        tradeTypes.delete(type);
      } else {
        tradeTypes.add(type);
      }

      setTradeTypes(new Set(tradeTypes));
    },
    [setTradeTypes, tradeTypes]
  );

  const memoizedTradeHistory = useMemo(() => {
    if (tradeTypes.size === 0) {
      return data?.trades.trade_history || [];
    }

    return (data?.trades.trade_history || []).filter((trade) => tradeTypes.has(trade.trade_type as TradeTypeEnum));
  }, [data, tradeTypes]);

  const columns: ITableColumn<IAevoTradeHistory>[] = useMemo(
    () => [
      {
        title: "ID",
        align: "left",
        accessor: "trade_id",
        Cell: ({ value }) => (
          <div style={{ display: "flex " }}>
            <RedirectButton
              onClick={(e) => {
                e.stopPropagation();
                onNavigateToDebug(value || "");
              }}
            >
              <img src={arrow} alt="arrow" />
            </RedirectButton>
            <div>{shortenString(value, 4, 4)} </div>
          </div>
        ),
      },
      {
        title: "Type",
        align: "left",
        accessor: "trade_type",
      },
      {
        title: "Order Type",
        align: "left",
        accessor: "order_type",
      },
      {
        title: "Created On",
        align: "left",
        accessor: "created_timestamp",
        valueExtractor: (value) =>
          moment(Number(value) / 1e6)
            .format("DD MMM YYYY HH:mm:ss")
            .toString(),
      },
      {
        title: "Instrument Name",
        align: "left",
        accessor: "instrument_name",
      },
      {
        title: "Side",
        align: "left",
        accessor: "side",
        Cell: ({ value }) => (
          <div
            style={{
              color: value === "buy" ? COLORS.green.one : COLORS.red.one,
            }}
          >
            {value}
          </div>
        ),
      },
      {
        title: "Price",
        align: "right",
        accessor: "price",
        valueExtractor: (value) => (value ? `$${value}` : ""),
      },
      {
        title: "Amount",
        align: "right",
        accessor: "amount",
        valueExtractor: (value) => `${value}`,
      },
      {
        title: "PnL",
        align: "right",
        accessor: "pnl",
        Cell: ({ value }) => (
          <Align align="right" style={{ color: getProfitTextColor(Number(value)) }}>
            {currency(value).format()}
          </Align>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tradeTypes, isMobile]
  );

  const firstTradeHistory = useMemo(
    () => moment(Number(memoizedTradeHistory[memoizedTradeHistory.length - 1]?.created_timestamp) / 1e6),
    [memoizedTradeHistory]
  );
  const lastTradeHistory = useMemo(
    () => moment(Number(memoizedTradeHistory[0]?.created_timestamp) / 1e6),
    [memoizedTradeHistory]
  );

  const queryContent = useMemo(() => {
    if (memoizedTradeHistory.length > 0) {
      if (endTime && lastTradeHistory.day() !== moment(endTime).day()) {
        return (
          <QueryTitle>
            <div>This query spans from</div>
            <span>{firstTradeHistory.format("DD MMM YYYY HH:mm")}</span>
            <div>{" to "}</div>
            <span>{lastTradeHistory.format("DD MMM YYYY HH:mm")}</span>
            <span style={{ color: COLORS.red.one }}>
              * Query does not reach the specified end date - {moment(endTime / 1e6).format("DD MMM YYYY")}
            </span>
          </QueryTitle>
        );
      }

      return (
        <QueryTitle>
          <div>This query spans from</div>
          <span>{firstTradeHistory.format("DD MMM YYYY HH:mm")}</span>
          <div>{" to "}</div>
          <span>{lastTradeHistory.format("DD MMM YYYY HH:mm")}</span>
        </QueryTitle>
      );
    }

    return undefined;
  }, [endTime, firstTradeHistory, lastTradeHistory, memoizedTradeHistory.length]);

  const csvData = useMemo(() => {
    if (memoizedTradeHistory && memoizedTradeHistory.length) {
      const headers = columns.map((col) => col.title || "");

      const csvRows =
        memoizedTradeHistory.map(
          (h: IAevoTradeHistory) => [
            h.trade_type,
            moment(Number(h.created_timestamp) / 1e6)
              .format("DD MMM YYYY HH:mm:ss")
              .toString(),
            h.instrument_name,
            h.side,
            parseFloat(h.amount).toFixed(2),
            parseFloat(h.price).toFixed(2),
            parseFloat(h.pnl).toFixed(2),
          ],
          []
        ) || [];

      return [headers, ...csvRows];
    }
    return undefined;
  }, [columns, memoizedTradeHistory]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: memoizedTradeHistory,
      defaultColumn: {
        Header: DefaultHeaderForColumn,
        Cell: DefaultCellForColumn,
      } as any,
      autoResetSortBy: false,
    },
    useSortBy
  );

  return (
    <PageGrid
      style={{
        width: "inherit",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto auto",
      }}
    >
      <div>
        <Flex gap={SPACING.three} wrap align="center" style={{ marginBottom: SPACING.two }}>
          <div>
            <FilterWrapper>Assets</FilterWrapper>
            <MarketDropdown onClick={setAsset} overridePlaceholder="All Trade Assets" />
          </div>

          <div>
            <FilterWrapper>Trade Types</FilterWrapper>
            <Select
              wrapperStyle={{
                display: "inline-flex",
              }}
              options={[
                {
                  label: "Trade",
                  isActive: tradeTypes.has(TradeTypeEnum.TRADE),
                  onClick: () => onSet(TradeTypeEnum.TRADE),
                },
                {
                  label: "Liquidation",
                  isActive: tradeTypes.has(TradeTypeEnum.LIQUIDATION),
                  onClick: () => onSet(TradeTypeEnum.LIQUIDATION),
                },
                {
                  label: "Settlement",
                  isActive: tradeTypes.has(TradeTypeEnum.SETTLEMENT),
                  onClick: () => onSet(TradeTypeEnum.SETTLEMENT),
                },
                {
                  label: "Funding",
                  isActive: tradeTypes.has(TradeTypeEnum.FUNDING),
                  onClick: () => onSet(TradeTypeEnum.FUNDING),
                },
              ]}
            />
          </div>

          <div>
            <FilterWrapper>Filter By</FilterWrapper>
            <TableFilterDropdown
              onClick={(type, value) => {
                if (type === TableFilterEnum.LIMIT) {
                  onSetLimit(value as number);
                } else {
                  onSetRange(value as Array<Date | undefined>);
                }
              }}
            />
          </div>
        </Flex>

        <div>{queryContent}</div>
        <TradeHistoryChart data={memoizedTradeHistory} startDate={firstTradeHistory} endDate={lastTradeHistory} />
      </div>
      <Collapse
        defaultActiveKey={["1"]}
        expandIconPosition="end"
        items={[
          {
            key: "1",
            label: <CollapseHeaderCount count={memoizedTradeHistory.length} showBadge title="Trade History" />,
            extra: !isMobile ? (
              <DownloadCSVButton data={csvData} filename={`Trade History ${moment().format("DD_MMM_YYYY_HH_mm_ss")}`} />
            ) : undefined,
            children: (
              <MinifiedTableWrapper>
                <TableLoader isLoading={isLoading || isValidating} />
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      // eslint-disable-next-line react/jsx-key
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column: any) => (
                          // eslint-disable-next-line react/jsx-key
                          <TableHeaderCell
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            style={{ width: column.width }}
                          >
                            {column.render("Header")}
                          </TableHeaderCell>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      const { ...rowProps } = row.getRowProps();
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <tr {...rowProps}>
                          {row.cells.map((cell) => (
                            // eslint-disable-next-line react/jsx-key
                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </MinifiedTableWrapper>
            ),
          },
        ]}
      />
    </PageGrid>
  );
}
