export interface IAssetConfigs {
  asset: string;
  instrument_type: string;
  account: string;
  initial_margin: string;
  maintenance_margin: string;
  portfolio_initial_margin: string;
  portfolio_maintenance_margin: string;
  taker_fee: string;
  maker_fee: string;
  premium_cap: string;
  liquidation_fee: string;
  liquidation_fee_cap: string;
}
export interface IGlobalAssetConfigs extends IAssetConfigs {
  price_step_size: string;
  amount_step_size: string;
  min_amount: string;
  max_amount: string;
  max_notional: string;
  price_band: string;
  price_band_index: string;
  insurance_fund: string;
  fair_impact_tolerance: string;
  liquidation_max_slippage: string;
  fair_impact_notional: string;
}

export const CONFIG_TITLES: Partial<Record<keyof IAssetConfigs, string>> = {
  initial_margin: "Initial Margin",
  maintenance_margin: "Maintenance Margin",
  portfolio_initial_margin: "Portfolio Initial Margin",
  portfolio_maintenance_margin: "Portfolio Maintenance Margin",
  taker_fee: "Taker Fee",
  maker_fee: "Maker Fee",
  premium_cap: "Premium Cap",
  liquidation_fee: "Liquidation Fee",
  liquidation_fee_cap: "Liquidation Fee Cap",
};

export const ADMIN_CONFIG_TITLES: Partial<
  Record<keyof IGlobalAssetConfigs, string>
> = {
  price_step_size: "Price Step Size",
  amount_step_size: "Amount Step Size",
  min_amount: "Min. Amount",
  max_amount: "Max. Amount",
  max_notional: "Max. Notional",
  price_band: "Price Band",
  price_band_index: "Price Band Index",
  insurance_fund: "Insurance Fund",
  fair_impact_tolerance: "Fair Impact Tolerance",
  liquidation_max_slippage: "Liquidation Max Slippage",
  fair_impact_notional: "Fair Impact Notional",
};
