import { googleLogout } from "@react-oauth/google";
import { useCallback, useContext, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/logo/logo_text_white.svg";
import { AuthContext } from "../../context/AuthContext";
import { PageEndpointEnum } from "../../enums/endpoints";
import { Divider, HeaderRow, LeftHeaderWrapper, LinkText, MainHeaderWrapper, RightHeaderWrapper } from "./style";
import { ToastEnum, ToastStatusEnum, useToast } from "../../hooks/toast";
import { ReactComponent as Copy } from "../../assets/svg/copy.svg";
import { Button, ButtonThemeEnum } from "../shared/Buttons/styles";
import { COLORS } from "../../constants/design/colors";
import { SPACING } from "../../constants/design/spacing";

interface ILink {
  title: string;
  to: string;
  isActive: boolean;
  isHidden?: boolean;
}

export function Header() {
  const location = useLocation();
  const { tokenId, setTokenId } = useContext(AuthContext);
  const { addToast } = useToast();

  const links: ILink[] = useMemo(
    () => [
      {
        title: "Dashboard",
        to: PageEndpointEnum.DASHBOARD,
        isActive: location.pathname === "/",
      },
      {
        title: "Book",
        to: PageEndpointEnum.BOOK,
        isActive: location.pathname === "/book",
      },
      {
        title: "Withdrawals",
        to: PageEndpointEnum.WITHDRAW,
        isActive: location.pathname === "/withdraw",
      },
      {
        title: "Risk",
        to: PageEndpointEnum.RISK,
        isActive: location.pathname === "/risk",
      },
      {
        title: "Settings",
        to: PageEndpointEnum.SETTINGS,
        isActive: location.pathname === "/settings",
      },
      {
        title: "Transactions",
        to: PageEndpointEnum.TX,
        isActive: location.pathname === "/tx",
      },
      {
        title: "MM Trades",
        to: PageEndpointEnum.MMTRADES,
        isActive: location.pathname === "/mm-trades",
      },
      {
        title: "Options Trades",
        to: PageEndpointEnum.OPTIONSTRADES,
        isActive: location.pathname === "/options-trades",
      },
      {
        title: "MM Strategies",
        to: PageEndpointEnum.MMSTRATEGIES,
        isActive: location.pathname === "/mm-strategies",
      },
      {
        title: "Debug",
        to: PageEndpointEnum.DEBUG,
        isActive: location.pathname === "/debug",
      },
      {
        title: "Volume",
        to: PageEndpointEnum.VOLUME,
        isActive: location.pathname === "/volume",
      },
      {
        title: "Intercom",
        to: PageEndpointEnum.INTERCOM,
        isActive: location.pathname === "/intercom",
      },
    ],
    [location.pathname]
  );

  const onCopy = useCallback(async () => {
    if (tokenId) {
      await navigator.clipboard.writeText(tokenId);
      addToast(
        {
          type: ToastEnum.SIMPLE,
          header: "Bearer Token Copied",
          status: ToastStatusEnum.SUCCESS,
        },
        4000
      );
    }
  }, [addToast, tokenId]);

  return (
    <HeaderRow>
      <div>
        <MainHeaderWrapper>
          <LeftHeaderWrapper>
            <Link to={PageEndpointEnum.DASHBOARD}>
              <Logo height={20} width={60} />
            </Link>
            <Divider />
            {links.map((link, i) => {
              if (!link.isHidden) {
                return (
                  <Link key={i} to={link.to}>
                    <LinkText isActive={link.isActive}>{link.title}</LinkText>
                  </Link>
                );
              }

              return null;
            })}
          </LeftHeaderWrapper>
          <RightHeaderWrapper>
            <Button style={{ marginRight: SPACING.two }} buttonTheme={ButtonThemeEnum.HIGHLIGHT} onClick={onCopy}>
              <Copy style={{ stroke: COLORS.highlight.one, marginRight: SPACING.two }} /> Copy Token
            </Button>
            <Button
              buttonTheme={ButtonThemeEnum.SYSTEM}
              onClick={() => {
                googleLogout();
                window.localStorage.removeItem("tokenId");
                setTokenId(undefined);
              }}
            >
              Logout
            </Button>
          </RightHeaderWrapper>
        </MainHeaderWrapper>
      </div>
    </HeaderRow>
  );
}
