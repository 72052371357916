// We use the same environment for development and staging

import {
  DeribitEndpointEnum,
  AdminEndpointEnum,
  BinanceEndpointEnum,
  AirtableEndpointEnum,
  PricerEndpointEnum,
  MMTradesEndpointEnum,
  FilledTradesEndpointEnum,
  IntercomEndpointEnum,
} from "../enums/endpoints";

// But we still need a switch to only show dev features locally
export const isStaging = () => process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === "testnet";
export const isProduction = () => process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === "master";
export const isDevelopment = () => !isStaging() && !isProduction();

export const CLIENT_ID = () => {
  let client_id: string = "";
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "testnet": {
      client_id = process.env.REACT_APP_ADMIN_CLIENT_ID_TESTNET as string;
      break;
    }
    case "local": {
      client_id = process.env.REACT_APP_ADMIN_CLIENT_ID_TESTNET as string;
      break;
    }
    case "mainnet": {
      client_id = process.env.REACT_APP_ADMIN_CLIENT_ID_MAINNET as string;
      break;
    }

    default:
      break;
  }
  return client_id;
};

export const DERIBIT_API_URL = (endpoint: DeribitEndpointEnum) => `${process.env.REACT_APP_AEVO_API_URL_MAINNET}/api/deribit/${endpoint}`;
export const BINANCE_API_URL = (endpoint: BinanceEndpointEnum) => `${process.env.REACT_APP_AEVO_API_URL_MAINNET}/api/binance/${endpoint}`;
export const AIRTABLE_API_URL = (endpoint: AirtableEndpointEnum) => `${process.env.REACT_APP_AEVO_API_URL_MAINNET}/api/airtable/${endpoint}`;
export const PRICER_API_URL = (endpoint: PricerEndpointEnum) => `${process.env.REACT_APP_AEVO_API_URL_MAINNET}/api/pricer/${endpoint}`;
export const MMTRADES_API_URL = (endpoint: MMTradesEndpointEnum) => `${process.env.REACT_APP_AEVO_API_URL_MAINNET}/api/mm/${endpoint}`;
export const TRADES_API_URL = (endpoint: FilledTradesEndpointEnum) => `${process.env.REACT_APP_AEVO_API_URL_MAINNET}/api/trades/${endpoint}`;
export const INTERCOM_API_URL = (endpoint: IntercomEndpointEnum) => `${process.env.REACT_APP_AEVO_API_URL_MAINNET}/api/intercom/${endpoint}`;

export const ADMIN_API_URL = (endpoint: AdminEndpointEnum | string) => {
  let url: string = "";
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "testnet": {
      url = `${process.env.REACT_APP_ADMIN_API_URL_TESTNET}/${endpoint}`;
      break;
    }
    case "mainnet": {
      url = `${process.env.REACT_APP_ADMIN_API_URL_MAINNET}/${endpoint}`;
      break;
    }
    case "local": {
      url = `${process.env.REACT_APP_ADMIN_API_URL_LOCAL}/${endpoint}`;
      break;
    }

    default:
      break;
  }
  return url;
};

export const AEVO_API_URL = (endpoint: AdminEndpointEnum | string) => `https://api.aevo.xyz/${endpoint}`;
