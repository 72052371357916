/* eslint-disable no-nested-ternary */
import styled from "styled-components";
import { BORDER_COLORS, COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { FONT_WEIGHT } from "../../../constants/design/fontWeight";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";

export const SelectWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  width: fit-content;
`;

export const OptionWrapper = styled.div<{ spacing?: number }>`
  display: flex;
  justify-content: center;
  position: relative;

  &:not(:last-of-type) {
    margin-right: ${({ spacing }) => spacing ?? SPACING.two}px;
  }
`;

export const Tag = styled.span`
  display: inline-block;
  margin-left: ${SPACING.one}px;
  padding: 0 ${SPACING.two}px;
  border-radius: ${SPACING.three}px;
`;

export const SelectButton = styled.button.attrs({
  type: "button",
})<{
  isActive?: boolean;
  isHighlighted?: boolean;
  isRound?: boolean;
  css?: any;
  size?: string;
  theme: "blue" | "purple";
}>`
  height: ${COMPONENTS.selectHeight}px;
  border-radius: ${({ isRound }) => (isRound ? 20 : 6)}px;
  color: ${(props) =>
    props.isActive || props.isHighlighted
      ? props.theme === "blue"
        ? COLORS.blue.one
        : COLORS.purple.one
      : TEXT_COLORS.two};
  box-shadow: ${(props) =>
    props.isActive ? `0 0 0 1px ${props.theme === "blue" ? COLORS.blue.two : COLORS.purple.two} inset` : "none"};
  padding: ${SPACING.two}px;
  background-color: ${COLORS.white.five};
  white-space: nowrap;
  transition: 0.2s all ease-in-out;

  ${Tag} {
    background-color: ${(props) =>
      props.isActive ? (props.theme === "blue" ? COLORS.blue.three : COLORS.purple.three) : COLORS.white.two};
  }

  font-size: ${({ size }) => size ?? FONT_SIZE.one};
  font-weight: ${FONT_WEIGHT.medium};
  ${(props) => props.css ?? null};

  display: flex;
  align-items: center;
`;
