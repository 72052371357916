import styled from "styled-components";
import { BORDER_COLORS, COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { FONT_SIZE } from "../../constants/design/fontSize";
import { SPACING, COMPONENTS } from "../../constants/design/spacing";

export const HeaderRow = styled.div`
  max-height: ${COMPONENTS.header.main}px;
  border-bottom: 1px solid ${BORDER_COLORS.two};
`;

export const MobileHeaderRow = styled.div`
  display: flex;
  height: ${COMPONENTS.header.mobile}px;
  justify-content: center;

  > a {
    margin: auto ${SPACING.two}px;
  }
`;

export const MainHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${BORDER_COLORS.two};
  padding: 0 ${SPACING.three}px;
  height: ${COMPONENTS.header.main}px;

  > * {
    margin: auto 0;
  }
`;

export const Divider = styled.div<{
  height?: string;
  horizontalMargin?: string;
}>`
  background-color: ${COLORS.white.three};
  width: 1px;
  height: ${({ height }) => height ?? "unset"};

  margin-left: ${({ horizontalMargin }) => horizontalMargin || `${SPACING.twoHalf}px`};
  margin-right: ${({ horizontalMargin }) => horizontalMargin || `${SPACING.twoHalf}px`};
`;

export const LeftHeaderWrapper = styled.div`
  font-size: ${FONT_SIZE.two};
  display: flex;

  a {
    color: ${TEXT_COLORS.two};
    text-decoration: none;
    margin: auto;

    margin-right: ${SPACING.twoHalf}px;
    margin-left: ${SPACING.twoHalf}px;

    &:first-child {
      margin-left: ${SPACING.two}px;
    }
  }
`;

export const RightHeaderWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  font-size: ${FONT_SIZE.two};

  > button {
    min-height: 42px;
  }
`;

export const NotificationButton = styled.button`
  width: 32px;
  height: 32px;
  padding: 0;
`;

export const LinkText = styled.span<{ isActive: boolean }>`
  transition: 0.2s ease-in-out;
  color: ${(props) => (props.isActive ? TEXT_COLORS.one : TEXT_COLORS.three)};

  &:hover {
    color: ${TEXT_COLORS.one};
  }
`;
