import styled from "styled-components";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { FONT_CSS } from "../../constants/design/fontSize";
import { TEXT_COLORS } from "../../constants/design/colors";

export const FilterContainer = styled.div`
  padding: 8px;
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100px;
`;

export const SelectWrapper = styled.div`
  overflow: hidden;
  overflow-x: scroll;
  padding: 0 ${SPACING.three}px;
  margin-bottom: ${SPACING.three}px;
`;

export const Title = styled.div`
  ${FONT_CSS.header.six}
  color: ${TEXT_COLORS.three};
  margin-bottom: ${SPACING.two}px;
  padding: ${SPACING.three}px;
  padding-bottom: 0px;
`;

export const BidsAsksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  margin: ${SPACING.two}px;
  display: grid;
  width: 100%;
  grid-template-columns: max-content 1fr;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const DebugWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - ${COMPONENTS.header.main}px);
  overflow: auto;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  flex-grow: 1;
  overflow: auto;
`;
