export interface IMMP {
  amount_change: string;
  amount_limit: string;
  enabled: boolean;
  frozen: boolean;
  frozen_end_time: string;
  interval: number;
  triggered: boolean;
  delta_limit: number;
}

export interface IAevoOrder {
  account: string;
  amount: string;
  created_timestamp: string;
  expiry: string;
  filled: string;
  initial_margin: string;
  instrument_id: string;
  instrument_name: string;
  option_type: string;
  order_id: string;
  order_status: string;
  order_type: string;
  price: string;
  side: "buy" | "sell";
  strike: string;
  system_type: string;
  timestamp: string;
  stop?: "STOP_LOSS" | "TAKE_PROFIT";
}

export interface IAevoOption {
  delta: string;
  expiry: string;
  gamma: string;
  iv: string;
  option_type: string;
  rho: string;
  strike: string;
  theta: string;
  vega: string;
}

export interface IAevoPosition {
  amount: string;
  asset: string;
  avg_entry_price: string;
  instrument_id: string;
  instrument_name: string;
  instrument_type: string;
  maintenance_margin: string;
  mark_price: string;
  option?: IAevoOption;
  option_type?: string;
  strike?: string;
  iv?: string;
  side: "buy" | "sell" | "-";
  unrealized_pnl: string;
  liquidation_price?: string;
}

export interface IAevoRiskPosition extends IAevoPosition {
  account: string;
  notional_value: string;
  tag: string;
}

export interface IAevoTransaction {
  account: string;
  amount: string;
  counter_party: string;
  collateral: string;
  collateral_name: string;
  finalized_timestamp: string;
  initiated_timestamp: string;
  l1_tx_hash: string;
  l2_tx_hash: string;
  tx_status: "initiated" | "finalized";
  tx_type: "deposit" | "withdraw" | "send" | "receive";
  chain_id: number;
  decimals: number;
}

export interface IAevoTradeHistory {
  account: string;
  amount: string;
  asset: string;
  created_timestamp: string;
  expiry: string;
  fees: string;
  instrument_id: string;
  instrument_name: string;
  instrument_type: string;
  option_type: string;
  payout: string;
  pnl: string;
  price: string;
  settlement_price: string;
  side: string;
  spot_price: string;
  strike: string;
  trade_id: string;
  trade_type: string;
  order_type: string;
}

export interface IAevoOrderHistory {
  count: string;
  order_history: IAevoOrderHistoryRow[];
}

export interface IAevoOrderHistoryRow {
  account: string;
  order_id: string;
  instrument_id: string;
  instrument_name: string;
  asset: string;
  instrument_type: string;
  order_type: string;
  side: string;
  amount: string;
  price: string;
  filled: string;
  order_status: string;
  error?: string;
  stop_type?: string;
  trigger?: string;
  created_timestamp: string;
  total_matched_amount?: string;
  trade_id?: string;
  avg_price?: string;
  entry_price?: string;
  spot_price?: string;
  fees?: string;
}

export enum AccountTypeEnum {
  STANDARD = "STANDARD",
  MARKET_MAKER = "MARKET_MAKER",
  INSURANCE_FUND = "INSURANCE_FUND",
  MANAGED_ACCOUNT = "MANAGED_ACCOUNT",
}

interface IUserMargin {
  used: string;
  balance: string;
}

interface IGreek {
  asset: string;
  delta: string;
  gamma: string;
  rho: string;
  theta: string;
  vega: string;
}

interface IPortfolio {
  balance: string;
  pnl: string;
  realized_pnl: string;
  win_rate: string;
  greeks: IGreek[];
  user_margin: IUserMargin;
}

export interface ICollateral {
  collateral_asset: string;
  balance: string;
  available_balance: string;
  withdrawable_balance: string;
  collateral_value: string;
  collateral_yield_bearing: boolean;
  collateral_price?: string;
  margin_value?: string;
}

export interface ILeverage {
  instrument_id: string;
  leverage: string;
  margin_type: "CROSS" | "ISOLATED";
}

export interface IPosition {
  asset: string;
  instrument_id: string;
  instrument_name: string;
  instrument_type: string;
  amount: string;
  value: string;
  mark_price: string;
  avg_entry_price: string;
  unrealized_pnl: string;
  maintenance_margin: string;
}

export interface IAccountPortfolio {
  portfolio: IPortfolio;
}

export interface IAccountOrders {
  open_orders: IAevoOrder[];
}

export interface IAccountTrades {
  trades: {
    count: string;
    trade_history: IAevoTradeHistory[];
  };
}

export interface IAccountTxs {
  tx_history: {
    count: string;
    transaction_history: IAevoTransaction[];
  };
}

export interface IAccount
  extends IAccountPortfolio,
    IAccountOrders,
    IAccountTrades,
    IAccountTxs {
  account: string;
  username: string;
  tag?: string;
  airtable_name?: string;
  referrer: string;
  account_type: AccountTypeEnum;
  available_balance: string;
  balance: string;
  equity: string;
  credit: string;
  in_liquidation: boolean;
  initial_margin: string;
  maintenance_margin: string;
  mmp: Record<string, IMMP>;
  positions: IAevoPosition[];
  email_address: string;
  is_portfolio: boolean;
  permissions: string[];
  referral_bonus: string;
  referee_discount: string;
  user_group: string;
  collaterals: ICollateral[];
  leverages: ILeverage[];
}

export interface IBankrupt {
  bankrupt_amount: string;
}

export interface IMaintenance {
  maintenance: boolean;
  priority: boolean;
}

export interface ITxServiceState {
  txindex: number;
  txs: string[];
  events: string[];
}

export interface IAccountCheck {
  account: string;
  state: {
    [key: string]: string;
  };
  transactions: {
    [key: string]: string;
  };
  trades: string;
  sum: string;
  difference: string;
}

export interface ISyncState {
  process: string;
  progress: string;
  report: string;
  last_requested: number;
  last_updated: number;
}

export interface ICapacity {
  socket_connector: string;
  capacity: string;
  chain_id: number;
}

export interface IDebugState {
  collateral_value: string;
  maintenance_margin: string;
  initial_margin: string;
  equity: string;
  collaterals: ICollateral[];
  positions: IPosition[];
}

export interface ITPSLHistory {
  account: string;
  order_id: string;
  instrument_id: string;
  instrument_name: string;
  instrument_type: string;
  order_type: string;
  side: string;
  amount: string;
  price: string;
  mark_price: string;
  event_type: string;
  filled: string;
  order_status: string;
  stop_type: string;
  trigger: string;
  created_timestamp: string;
}

export interface IOrderbookState {
  instrument_id: string;
  instrument_name: string;
  bids: string[][];
  asks: string[][];
}
