import currency from "currency.js";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useSortBy, useTable } from "react-table";
import { Link } from "react-router-dom";
import { Collapse } from "antd";
import { ReactComponent as Arrow } from "../../../assets/svg/arrow-up-right.svg";
import { IAevoTransaction } from "../../../interfaces/aevo";
import { chainIdToExplorer, environmentToAevoExplorer } from "../../../utils/chain";
import { DefaultCellForColumn } from "../../Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../../Table/DefaultHeaderForColumn";
import { ITableColumn } from "../../Table/TableColumn";
import { Align, MinifiedTableWrapper, PageGrid, TableHeaderCell, TableLoader, TitleWrapper } from "../../Table/style";
import { SPACING } from "../../../constants/design/spacing";
import { Select } from "../../shared/Select";
import { QueryTitle } from "../style";
import TxHistoryChart from "../charts/TxHistoryChart";
import { shortenAddress } from "../../../utils/strings";
import { STRATEGIES_LIST, STRATEGY_ADDRESSES_TO_NAME } from "../../../constants/strategies";
import { COLORS } from "../../../constants/design/colors";
import { PageEndpointEnum } from "../../../enums/endpoints";
import { IAirtableRecord } from "../../../hooks/api/admin/useAirtableTags";
import CollapseHeaderCount from "../../TableCollapse";

interface ITxHistoryTableProps {
  data: IAevoTransaction[];
  records: IAirtableRecord;
  isLoading?: boolean;
  isMobile?: boolean;
}

// Boolean here represents whether the type is a credit
export const TX_TYPES: Record<string, [string, boolean | undefined]> = {
  deposit: ["Deposit", true],
  withdraw: ["Withdraw", false],
  send: ["Send", false],
  receive: ["Receive", true],
  credit: ["Credit", true],
  swap: ["Swap", undefined],
  yv_withdraw: ["Vault Withdraw", undefined],
  yv_deposit: ["Vault Deposit", undefined],
};

export function TxHistoryTable({ data, records, isLoading, isMobile }: ITxHistoryTableProps) {
  const [txTypes, setTxTypes] = useState<Set<string>>(
    new Set<string>(["deposit", "withdraw", "send", "receive", "credit"])
  );

  const onSet = useCallback(
    (type: string) => {
      if (txTypes.has(type)) {
        txTypes.delete(type);
      } else {
        txTypes.add(type);
      }

      setTxTypes(new Set(txTypes));
    },
    [setTxTypes, txTypes]
  );

  const getCounterpartyDetails = useCallback(
    (address: string, tx_type: "deposit" | "withdraw" | "send" | "receive") => {
      if (records[address]) {
        return (
          <Link to={`${PageEndpointEnum.ACCOUNT}/${address}`}>
            <div>{tx_type === "send" || tx_type === "deposit" ? "To:" : "From:"}</div>
            <div style={{ color: COLORS.blue.one, marginLeft: SPACING.one }}>{records[address]}</div>
            <Arrow style={{ stroke: COLORS.blue.one }} />
          </Link>
        );
      }
      if (STRATEGIES_LIST.includes(address)) {
        return (
          <Link to={`${PageEndpointEnum.ACCOUNT}/${address}`}>
            <div>{tx_type === "send" || tx_type === "deposit" ? "To:" : "From:"}</div>
            <div style={{ color: COLORS.blue.one, marginLeft: SPACING.one }}>{STRATEGY_ADDRESSES_TO_NAME[address]}</div>
            <Arrow style={{ stroke: COLORS.blue.one }} />
          </Link>
        );
      }

      return (
        <Link to={`${PageEndpointEnum.ACCOUNT}/${address}`}>
          <div>{tx_type === "send" || tx_type === "deposit" ? "To:" : "From:"}</div>
          <div style={{ marginLeft: SPACING.one }}>
            {shortenAddress(address)}
            <Arrow />
          </div>
        </Link>
      );
    },
    [records]
  );

  const columns: ITableColumn<IAevoTransaction>[] = useMemo(
    () => [
      {
        title: "Type",
        align: "left",
        accessor: "tx_type",
      },
      {
        title: "Finalized On",
        align: "left",
        accessor: "finalized_timestamp",
        valueExtractor: (value) =>
          moment(value / 1e6)
            .format("DD MMM YYYY HH:mm:ss")
            .toString(),
      },
      {
        title: "Amount",
        align: "right",
        accessor: "amount",
        Cell: ({ row }) => (
          <Align align="right">
            {currency(parseFloat(row.original.amount) / 10 ** row.original.decimals).format({
              symbol: "",
            })}{" "}
            {row.original.collateral_name}
          </Align>
        ),
      },
      {
        title: "Counterparty",
        align: "right",
        accessor: "counter_party",
        Cell: ({ value, row }) => (
          <Align align="right">{value ? getCounterpartyDetails(value, row.original.tx_type) : "-"}</Align>
        ),
      },
      {
        title: "Tx Hash",
        align: "right",
        accessor: "l1_tx_hash",
        Cell: ({ row }) => (
          <Align align="right">
            {row.original.l1_tx_hash ? (
              <a
                href={`${chainIdToExplorer(row.original.chain_id)}tx/${row.original.l1_tx_hash}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                L1 <Arrow />
              </a>
            ) : null}
            {row.original.l2_tx_hash ? (
              <a
                href={`${environmentToAevoExplorer()}tx/${row.original.l2_tx_hash}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                L2 <Arrow />
              </a>
            ) : null}
          </Align>
        ),
      },
    ],
    []
  );

  const memoizedTxs = useMemo(() => data.filter((tx) => txTypes.has(tx.tx_type)), [data, txTypes]);

  const startDate = useMemo(
    () => moment(Number(memoizedTxs[memoizedTxs.length - 1]?.finalized_timestamp) / 1e6),
    [memoizedTxs]
  );

  const endDate = useMemo(() => moment(Number(memoizedTxs[0]?.finalized_timestamp) / 1e6), [memoizedTxs]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: memoizedTxs,
      defaultColumn: {
        Header: DefaultHeaderForColumn,
        Cell: DefaultCellForColumn,
      } as any,
      autoResetSortBy: false,
    },
    useSortBy
  );

  return (
    <PageGrid
      style={{
        width: "inherit",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto auto",
      }}
    >
      <div>
        <TitleWrapper>
          <div>Transaction History {data.length ? `(${memoizedTxs.length} out of ${data.length})` : "(None)"}</div>
        </TitleWrapper>
        <Select
          wrapperStyle={{
            marginTop: SPACING.two,
            marginBottom: SPACING.two,
            textTransform: "capitalize",
          }}
          options={Object.entries(TX_TYPES).map(([type, value]) => ({
            label: String(value[0]),
            value: type,
            isActive: txTypes.has(type),
            onClick: () => onSet(type),
          }))}
        />
        {memoizedTxs.length > 1 ? (
          <QueryTitle>
            <div>This query spans from</div>
            <span>{startDate.format("DD MMM YYYY HH:mm")}</span>
            <div>{" to "}</div>
            <span>{endDate.format("DD MMM YYYY HH:mm")}</span>
          </QueryTitle>
        ) : undefined}
        <TxHistoryChart data={memoizedTxs} startDate={startDate} endDate={endDate} />
      </div>

      <Collapse
        defaultActiveKey={["1"]}
        expandIconPosition="end"
        items={[
          {
            key: "1",
            label: <CollapseHeaderCount count={memoizedTxs.length} showBadge title="Transaction History" />,
            children: (
              <MinifiedTableWrapper isMobile={isMobile}>
                <TableLoader isLoading={isLoading} />
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      // eslint-disable-next-line react/jsx-key
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column: any) => (
                          // eslint-disable-next-line react/jsx-key
                          <TableHeaderCell
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            style={{ width: column.width }}
                          >
                            {column.render("Header")}
                          </TableHeaderCell>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      const { ...rowProps } = row.getRowProps();
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <tr {...rowProps}>
                          {row.cells.map((cell) => (
                            // eslint-disable-next-line react/jsx-key
                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </MinifiedTableWrapper>
            ),
          },
        ]}
      />
    </PageGrid>
  );
}
